import React, { useEffect, useState } from "react";
import { Descriptions, Button, Divider } from "antd";

const HSCRequestData = ({formName, formDescription, formBodyFields, formKey, RequestID, host}) => {
    const [name, setName] = useState(formName);
    const [description, setDescription] = useState(formDescription);
    const [bodyFields, setBodyFields] = useState(formBodyFields);
    const [apiKey, setApiKey] = useState(formKey);
    const [reqID, setReqID] = useState(RequestID);

    useEffect(() => {
        setName(formName);
        setDescription(formDescription);
        setBodyFields(formBodyFields);
        setApiKey(formKey);
        setReqID(RequestID)
    }, [formName, formDescription, formBodyFields, formKey, RequestID]);

    function getFieldExample(type){
        switch(type){
            case "bigint":
                return <span className="numberValueField">{"10"}</span>
            case "boolean":
                return <span className="boolValueField">{"true"}</span>
            case "character varying":
                return <span className="normalValueField">{"\"cadena de texto\""}</span>
            case "date":
                return <span className="normalValueField">{"\"1914-12-24\""}</span>
            case "double":
                return <span className="numberValueField">{"3.141592"}</span>
            case "time":
                return <span className="normalValueField">{"\"01:10:30\""}</span>
            case "timestamp without time zone":
                return <span className="normalValueField">{"\"1914-12-24 01:10:30\""}</span>
        }
    }

    function getBody(){
        return bodyFields.map(function(b, i){
            let comma="";
            if(i<bodyFields.length-1)
                comma=",";
            return <><br/>&nbsp;&nbsp;&nbsp;&nbsp;<span className="fieldNameColor">{"\""+b.field_name+"\" : "}</span>{getFieldExample(b.field_type)}{comma}</>
        });
    }

    function getFields(){
        return bodyFields.map(function(b, i){
            return <div className="textBackGround">
                        <span className="FieldRedColor">{"<campo name"}</span>
                        <span className="FieldBlueColor">{"="}</span>
                        <span className="FieldGreenColor">{`"${b.field_name}"`}</span>
                        <span className="FieldRedColor">{" calc"}</span>
                        <span className="FieldBlueColor">{"="}</span>
                        <span className="FieldGreenColor">{`"%api.json.${b.field_name}%"`}</span>
                        <span className="FieldRedColor">{" />"}</span>
                        <br/>
                    </div>
        });
    }

    return (
        <>
            <Descriptions className="selectableData" title="Datos de la Petición" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                <Descriptions.Item span={4} label="URL">{`${host}/api/${reqID}`}</Descriptions.Item>
                <Descriptions.Item span={4} label="Encabezados">
                    {"Access-Control-Allow-Origin : *"}
                    <br/>
                    {"API-Key : "+apiKey}
                </Descriptions.Item>
                <Descriptions.Item span={4} label="JSON">
                    {"{"}
                    {getBody()}
                    <br/>
                    {"}"}
                </Descriptions.Item>
            </Descriptions>
            <Divider type="horizontal" />
            <Descriptions className="selectableData" title="Campos para la Tarea" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                <Descriptions.Item span={4} label="Campos">
                    {getFields()}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default HSCRequestData;