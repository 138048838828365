import React from 'react';
import ReactDOM from 'react-dom';
import HSCFetchManager from './HSCFetchManager/HSCFetchManager';
//import 'antd/dist/antd.css'

// uncomment this block to "defer" ReactDOM.render and expose it globaly
window.HSCFetchManager = {
    mount: (props, container) => {
        ReactDOM.render(<HSCFetchManager {...props} />, container);
    },
    unmount: (container) => {
        ReactDOM.unmountComponentAtNode(container);
    }
}


console.log("HSCFetchManager version: v1.0.0.7")