import React, { useEffect, useState } from "react";
import {Button, Col, Row} from 'antd';
//import 'antd/dist/antd.css'
import './manager.css'
// import _ from 'lodash'

const HSCActionButtons = ({saveData, getLogs, getPetitionData,deletePetition, isNewData}) => {
    const [isNewDataVal, setIsNewDataVal] = useState(isNewData);

    useEffect(() => {
        setIsNewDataVal(isNewData)
    }, [isNewData]);

    return (
        <Row>
            <Col span={1}/>
            <Col span={22}>
            <Row>
                <Col span={3}/>
                <Col span={2}>
                    <Button type="primary" onClick={saveData}>Guardar</Button>
                </Col>
                <Col span={6}/>
                <Col span={2}>
                    <Button type="default" disabled={isNewDataVal} onClick={getPetitionData}>Ver petición</Button>
                </Col>
                <Col span={6}/>
                <Col span={2}>
                    <Button type="danger" disabled={isNewDataVal} onClick={deletePetition}>Eliminar petición</Button>
                </Col>
                </Row>
            </Col>
            <Col span={1}/>
        </Row>
    )
}

export default HSCActionButtons;