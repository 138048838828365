import React, { Component } from "react";
import {Skeleton, Layout, notification, Button, Menu, Select, Modal, Badge} from 'antd';
import 'antd/dist/antd.css'
import './manager.css'
import _ from 'lodash'
import {getProjectGeneralTasks, getProjectRequests, saveProjectPetition, getProjectUsers, getProjectEnvironment, deleteProjectPetition} from '../api/api';
import HSCFetchManagerFormContainer from "./HSCFetchManagerFormContainer";
import HSCRequestData from "./HSCRequestData";
const { Header, Footer, Sider, Content } = Layout;

export default class HSCFetchManager extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            renderForm:false,
            isNewData:false,
            selectedData:null,
            menuData:[],
            userOptions: [],
            generalTasks:[],
            bodyFields:[],
            logsModal:false,
            requestModal:false,
            selectedIndex:-1,
            hostURL:"",
            objectsByKey:{},
            selectedKeys:[],
            updateKeyModal:false
        }
    }


    componentDidMount() {
        let environmentFetch = getProjectEnvironment(this.props.REST_API_PREFIX,
            this.props.enterpriseID,
            this.props.token);
        environmentFetch.then((environmentData) => {
            if (environmentData!=null && environmentData.status === "success") {
                if(environmentData.data!=null){
                    this.setState(
                        {
                            hostURL: environmentData.data.backendHost
                        }
                    );
                }
            }else{
                let error = {
                    title: 'Lo sentimos.',
                    message: `No se encontro información para el proyecto [${this.props.enterpriseID}]`,
                };
                notification.error({
                message: error.title,
                description: error.message
                });
            }
        });
        let requestsFetch = getProjectRequests(this.props.REST_API_PREFIX,
        this.props.enterpriseID,
        this.props.token);
        let gTFetch = getProjectGeneralTasks(this.props.REST_API_PREFIX,
            this.props.enterpriseID,
            this.props.token)
        let userFetch = getProjectUsers(this.props.REST_API_PREFIX,
                this.props.enterpriseID,
                this.props.token)
        requestsFetch.then((requestsData) => {
            gTFetch.then((gtData) => {
                userFetch.then((userData) => {
                    if (requestsData!=null && requestsData.status === "success") {
                        if (gtData!=null && gtData.status === "success") {
                            if (userData!=null && userData.status === "success") {
                                let menuData=[];
                                let objectsByKey={};
                                for(let i=0;i<requestsData.data.length;i++){
                                    let obj = requestsData.data[i];
                                    //let menuItem = <Menu.Item onClick={() => { this.selectRequest(i) }} key={obj.requestID}>{obj.name}</Menu.Item>
                                    let menuItem = {
                                        label: obj.name,
                                        key: obj.requestID,
                                    }
                                    objectsByKey[obj.requestID]=obj;
                                    menuData.push(menuItem);
                                }
                                let userOptions = [];
                                if (userData.data != null && userData.data != null) {
                                    for (let i = 0; i < userData.data.length; i++) {
                                        let user = userData.data[i];
                                        if (user.user != null && user.name != null) {
                                            userOptions.push(<Select.Option key={user.user} value={user.user}>{user.name}</Select.Option>);
                                        }
                                    }
                                }
                                this.setState({
                                    renderForm:true,
                                    generalTasks:gtData.data.tasksData,
                                    userOptions: userOptions,
                                    menuData,
                                    objectsByKey:objectsByKey
                                })
                            }else{
                                let error = {
                                    title: 'Lo sentimos.',
                                    message: `No se encontro información para el proyecto [${this.props.enterpriseID}]`,
                                };
                                notification.error({
                                message: error.title,
                                description: error.message
                                });
                            }
                        }else{
                            let error = {
                                title: 'Lo sentimos.',
                                message: `No se encontro información para el proyecto [${this.props.enterpriseID}]`,
                            };
                            notification.error({
                            message: error.title,
                            description: error.message
                            });
                        }
                    }else{
                        let error = {
                            title: 'Lo sentimos.',
                            message: `No se encontro información para el proyecto [${this.props.enterpriseID}]`,
                        };
                        notification.error({
                        message: error.title,
                        description: error.message
                        });
                    }
                });
            });
        });
    }

    menuSelected = (item) =>{
        this.selectRequest(item.key)
    }

    selectRequest = (key) =>{
        this.setState(
            {
                selectedKeys:[key],
                selectedIndex:key,
                isNewData:false,
                selectedData:this.state.objectsByKey[key],
                dataSelected:true,
                bodyFields:this.state.objectsByKey[key].bodyFields
            }
        )
    }

    deleteRequest=()=>{
        let method ="DELETE";
        let requestID = this.state.selectedData.requestID;
        
        let dataForPost = {
            enterpriseID:this.props.enterpriseID,
        }
        let saveData = deleteProjectPetition(this.props.REST_API_PREFIX,
            this.props.enterpriseID,
            this.props.token,
            dataForPost,
            method,
            requestID)
            saveData.then((fetchData) => {
                if (fetchData!=null && fetchData.status === "success") {
                    let objectsByKey = this.state.objectsByKey;
                    let menuData = this.state.menuData;
                    delete objectsByKey[this.state.selectedIndex]
                    this.setState(
                        {
                            isNewData:true,
                            selectedData:{name:"",description:"", bodyFields:[], keyValue:"", taskID:""},
                            bodyFields:[],
                            dataSelected:true,
                            menuData:null,
                            deleteModal:false,
                            objectsByKey:objectsByKey
                        }, () =>{
                            for(let ii=0;ii<menuData.length;ii++){
                                if(menuData[ii].key==this.state.selectedIndex){
                                    menuData.splice(ii,1);
                                }
                            }
                            this.setState(
                                {
                                    menuData:menuData,
                                    selectedIndex:"",
                                    selectedKeys:[]
                                }
                            );
                        }
                    )
                }else{
                    let error = {
                        title: 'Lo sentimos.',
                        message: `Ocurrio un error al eliminar la peticion.`,
                      };
                    notification.error({
                      message: error.title,
                      description: error.message
                    });
                }
            });
    }

    confirmUpdateAPIKEY=()=>{
        let requestID = this.state.selectedData.requestID;
        let dataForPost = {
            enterpriseID:this.props.enterpriseID,
        }
        let saveData = saveProjectPetition(this.props.REST_API_PREFIX,
            this.props.enterpriseID,
            this.props.token,
            dataForPost,
            "PUT",
            requestID)
            saveData.then((fetchData) => {
                if (fetchData!=null && fetchData.status === "success") {
                    let selectedData = fetchData.data;
                        let requestsData = this.state.objectsByKey;
                        requestsData[selectedData.requestID].keyValue = selectedData.keyValue;
                        let sd = this.state.selectedData;
                        sd.keyValue = selectedData.keyValue;
                        this.setState(
                            {
                                selectedData:sd,
                                objectsByKey:{...requestsData},
                                updateKeyModal:false
                            }
                        )
                        notification.success({
                            message: "Peticion Actualizada",
                            description: "Peticion Actualizada Correctamente"
                        });
                    
                }else{
                    let error = {
                        title: 'Lo sentimos.',
                        message: `No se encontro un ambiente para el proyecto [${this.props.enterpriseID}]`,
                      };
                    notification.error({
                      message: error.title,
                      description: error.message
                    });
                }
            });
    }

    saveFormData = (data,table) =>{
        let method ="POST";
        let requestID = "";
        if(!this.state.isNewData){
            method="PATCH";
            requestID = this.state.selectedData.requestID;
        }
        
        let dataForPost = {
            enterpriseID:this.props.enterpriseID,
            "name":data.name,
            description:data.description,
            requestType:data.petition,
            bodyType:data.body,
            bodyFields:[...this.state.bodyFields],
            keyValue:data.keyValue,
            taskID:data.taskID,
            executeUser:data.executeUser
        }
        let saveData = saveProjectPetition(this.props.REST_API_PREFIX,
            this.props.enterpriseID,
            this.props.token,
            dataForPost,
            method,
            requestID)
            saveData.then((fetchData) => {
                if (fetchData!=null && fetchData.status === "success") {
                    let selectedData = fetchData.data;
                    if(this.state.isNewData){
                        let requestsData = this.state.objectsByKey;
                        let menuData = this.state.menuData;
                        requestsData[selectedData.requestID]=selectedData;
                            //let menuItem = <Menu.Item onClick={() => { this.selectRequest(this.state.requestsData.length) }} key={selectedData.requestID}>{selectedData.name}</Menu.Item>
                            let menuItem = {
                                label: selectedData.name,
                                key: selectedData.requestID,
                            }
                            menuData.push(menuItem);
                        this.setState(
                            {
                                isNewData:false,
                                selectedData:selectedData,
                                objectsByKey:{...requestsData},
                                dataSelected:true,
                                bodyFields:selectedData.bodyFields,
                                menuData:[...menuData],
                                selectedKeys:[selectedData.requestID],
                                selectedIndex:selectedData.requestID
                            }
                        )
                        notification.success({
                            message: "Peticion Creada",
                            description: "Peticion Creada Correctamente"
                        });
                        
                    } else {
                        let requestsData = this.state.objectsByKey;
                        requestsData[selectedData.requestID] = selectedData;
                        this.setState(
                            {
                                selectedData:selectedData,
                                objectsByKey:{...requestsData},
                                bodyFields:selectedData.bodyFields
                            }
                        )
                        notification.success({
                            message: "Peticion Actualizada",
                            description: "Peticion Actualizada Correctamente"
                        });
                    }
                }else{
                    let error = {
                        title: 'Lo sentimos.',
                        message: `No se encontro un ambiente para el proyecto [${this.props.enterpriseID}]`,
                      };
                    notification.error({
                      message: error.title,
                      description: error.message
                    });
                }
            });
    }

    createNewPetition = () =>{
        this.setState(
            {
                selectedKeys:[],
                selectedIndex:"",
                isNewData:true,
                selectedData:{name:"",description:"", bodyFields:[], keyValue:"", taskID:""},
                bodyFields:[],
                dataSelected:true
            }
        )
    }

    SetCurrentData = (currentBodyData) =>{
        this.setState({
            bodyFields:[...currentBodyData]
        })
    }

    getTableData = () => {
        return this.state.bodyFields
    }

    getLogs = () =>{
        
        this.setState({
            logsModal:true
        })
    }

    getPetitionData = () =>{
        
        this.setState({
            requestModal:true
        })
    }

    hideModals = () =>{
        this.setState({
            logsModal:false,
            requestModal:false,
            deleteModal:false,
            updateKeyModal:false
        })
    }

    deletePetition = () =>{
        this.setState({
            deleteModal:true
        })
    }

    updateAPIKEY = () =>{
        this.setState({
            updateKeyModal:true
        })
    }

    render() {
        let component = this.state.dataSelected ?
            <HSCFetchManagerFormContainer 
                saveFormData={this.saveFormData}
                formName={this.state.selectedData.name}
                formDescription={this.state.selectedData.description}
                formBodyFields={this.state.selectedData.bodyFields}
                formKey={this.state.selectedData.keyValue}
                formTaskID={this.state.selectedData.taskID}
                generalTasks={this.state.generalTasks}
                userOptions={this.state.userOptions}
                formExecuteUser={this.state.selectedData.executeUser}
                SetCurrentData={this.SetCurrentData}
                getTableData={this.getTableData}
                getPetitionData={this.getPetitionData}
                getLogs={this.getLogs}
                deletePetition={this.deletePetition}
                isNewData={this.state.isNewData}
                updateAPIKEY={this.updateAPIKEY}
            />
            :
            <div className="emptyContainerDiv"></div>;
        return (
            this.state.renderForm ?
            <>
                <Layout style={{"height":"100%"}}>
                    <Layout className="form-container-layout">
                        <Sider>
                            <div className='addTableContainer'
                                style={{"textAlign": "center",
                                    "marginTop": "15px"}}
                            >
                                <Button onClick={() => { this.createNewPetition() }}>Crear Peticion</Button></div>
                                <Menu 
                                    theme="light" 
                                    mode="inline" 
                                    openKeys={this.state.openKeys} 
                                    items={this.state.menuData}
                                    onSelect={this.menuSelected}
                                    selectedKeys={this.state.selectedKeys}
                                />
                        </Sider>
                        <Content className="form-container-content">
                            {component}
                        </Content>
                    </Layout>
                </Layout>
                <Modal
                    title="Logs"
                    open={this.state.logsModal}
                    visible={this.state.logsModal}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={this.hideModals}
                >

                </Modal>
                <Modal
                    title="Petición"
                    open={this.state.requestModal}
                    visible={this.state.requestModal}
                    destroyOnClose={true}
                    footer={null}
                    onCancel={this.hideModals}
                    width={800}
                >
                    <HSCRequestData
                        formName={this.state.selectedData!=null ? this.state.selectedData.name:""}
                        formDescription={this.state.selectedData!=null ? this.state.selectedData.description:""}
                        formBodyFields={this.state.selectedData!=null ? this.state.selectedData.bodyFields:""}
                        formKey={this.state.selectedData!=null ? this.state.selectedData.keyValue:""}
                        RequestID={this.state.selectedData!=null ? this.state.selectedData.requestID:""}
                        host={this.state.hostURL}
                    />
                </Modal>
                <Modal
                    title="¿Borrar Petición?"
                    open={this.state.deleteModal}
                    visible={this.state.deleteModal}
                    destroyOnClose={true}
                    onOk={this.deleteRequest}
                    onCancel={this.hideModals}
                >
                    <p>
                        <Badge status="error" text="Si elimina la petición no se podra recuperar." />
                        <Badge status="error" text="Es necesario volver a compilar el proyecto para que no se pueda seguir usando." />
                    </p>
                </Modal>
                <Modal
                    title="¿Actualizar API-KEY?"
                    open={this.state.updateKeyModal}
                    visible={this.state.updateKeyModal}
                    destroyOnClose={true}
                    onOk={this.confirmUpdateAPIKEY}
                    onCancel={this.hideModals}
                >
                    <p>
                        <Badge status="error" text="Es necesario volver a compilar el proyecto para actualizar el API-KEY de la petición." />
                    </p>
                </Modal>
            </>
            :
                <Skeleton active />
        )
    }
}