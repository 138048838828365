import { notification } from 'antd';

const getProjectEnvironment = async (
    url,
    enterprise,
    token,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let data = await fetch(url+`/runtime/enterprises/${enterprise}/environments/construction`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

  const saveProjectPetition = async (
    url,
    enterprise,
    token,
    body,
    method,
    requestID,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let fullURL = url+`/admin/projects/${enterprise}/request`;
    let fURL= requestID!="" ? fullURL+"/"+requestID:fullURL;
    let data = await fetch(fURL, {
      method: method,
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      },
      body:JSON.stringify(body)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

  const getProjectRequests = async (
    url,
    enterprise,
    token,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let data = await fetch(url+`/admin/projects/${enterprise}/request`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

  const getProjectGeneralTasks = async (
    url,
    enterprise,
    token,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let data = await fetch(url+`/admin/projects/${enterprise}/generalTasks?totalRows=99999&page=1&taskQuery=&roleQuery=`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

  const getProjectUsers = async (
    url,
    enterprise,
    token,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let data = await fetch(url+`/admin/projects/${enterprise}/users`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

const deleteProjectPetition = async (
    url,
    enterprise,
    token,
    body,
    method,
    requestID,
    handleResponseFunction,
    handleErrorFunction
  ) => {
    let fullURL = url+`/admin/projects/${enterprise}/request`;
    let fURL= requestID!="" ? fullURL+"/"+requestID:fullURL;
    let data = await fetch(fURL, {
      method: method,
      headers: {
        Authorization: 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      },
      body:JSON.stringify(body)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return response.json();
        else {
          if (response.status === 404) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'No se encontro la url solicitada [' + url + '].',
            });
          } else if (response.status === 403) {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message:
                'No cuentas con los permisios necesarios para realizar estra operación.',
            });
          } else {
            throw JSON.stringify({
              title: 'Lo sentimos.',
              message: 'Oucrrió un error desconocido alp rocesar la petición.',
            });
          }
        }
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        let errorStyle = {
          width: 500,
          marginLeft: 335 - 500,
        };
  
        if (error.name === 'TypeError')
          error = {
            title: 'Lo sentimos.',
            message: 'El recurso solicitado no está disponible [' + url + '].',
          };
  
        notification.error({
          message: error.title,
          description: error.message,
          style: errorStyle,
          duration: 0,
        });
        if (handleErrorFunction) return handleErrorFunction(error);
      });
  
    if (handleResponseFunction) {
      handleResponseFunction(data);
    }
    return data;
  };

  export {
    getProjectEnvironment,
    getProjectRequests,
    getProjectGeneralTasks,
    saveProjectPetition,
    getProjectUsers,
    deleteProjectPetition
  };