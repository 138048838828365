import {
  Form,
  Input,
  Select,
  Popconfirm,
  Table,
  Button,
  Col,
  Row,
  PageHeader,
} from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";

const BodyData = ({ SetCurrentData, bodyFields }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(bodyFields);
  const [tableData, setTableData] = useState([]);
  const [formDataSet, setformDataSet] = useState(false);
  const [cMax, setcMax] = useState(0);

  useEffect(
    () => {
      //SetDataGetter("table",getFormTable);
      if (!formDataSet) {
        setformDataSet(true);
        if (
          bodyFields.length > 0 &&
          bodyFields[bodyFields.length - 1] != null &&
          bodyFields[bodyFields.length - 1].fieldID != null &&
          bodyFields[bodyFields.length - 1].fieldID > 0
        ) {
          setcMax(bodyFields[bodyFields.length - 1].fieldID);
        }
      }
      if (bodyFields != data) {
        setData(bodyFields);
      }
    },
    [bodyFields]
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {

    function handleSave({ target: { value } }) {
      if (bodyFields.length >= 10 && bodyFields.length<=20){
        index = index + 10
      }else if(bodyFields.length >= 20 && bodyFields.length<=30) {
        index = index + 20
      }else if(bodyFields.length >= 30 && bodyFields.length<=40) {
        index = index + 30
      }else if(bodyFields.length >= 40 && bodyFields.length<=50) {
        index = index + 40
      }
      restProps.handleSave(value, index, dataIndex);
    }

    function handleCange(value) {
      restProps.handleSave(value, index, dataIndex);
    }

    function getInput() {
      if (inputType === "select") {
        return (
          <Select
            onSelect={handleCange}
            defaultValue={record.field_type}
            className="tableSelectColumn"
            dropdownMatchSelectWidth={true}
          >
            {/* onChange={this.state.selectchange} */}
            <Select.Option value="bigint">Entero</Select.Option>
            <Select.Option value="boolean">Booleano</Select.Option>
            <Select.Option value="character varying">Texto</Select.Option>
            <Select.Option value="date">Fecha</Select.Option>
            <Select.Option value="double">Decimal</Select.Option>
            {/* <Select.Option value="numeric">Numeric</Select.Option> */}
            {/* <Select.Option value="serial">Serial</Select.Option> */}
            {/* <Select.Option value="text">Text</Select.Option> */}
            <Select.Option value="time">Hora</Select.Option>
            <Select.Option value="timestamp without time zone">
              Fecha-Hora
            </Select.Option>
            {/* <Select.Option value="xml">XML</Select.Option> */}
          </Select>
        );
      }
      return <Input onBlur={handleSave} defaultValue={record.field_name} />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex + index}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `El campo no puede ir vacio`,
              },
            ]}
          >
            {getInput()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  function handleDeleteValue(index) {
    let dataValues = data;
    dataValues.splice(index, 1);
    setData([...dataValues]);
  }

  function addRow() {
    let dataValues = data;
    let newData = {
      key: cMax + 1,
      field_name: "campo_" + (cMax + 1),
      field_type: "character varying",
    };
    setData([...dataValues, newData]);
    SetCurrentData([...dataValues, newData]);
    setcMax(cMax + 1);
  }

  function handleSave(value, index, dataIndex) {
    console.log(value, index, dataIndex);
    console.log(data)
    let dataValues = data;
    dataValues[index][dataIndex] = value;
    setData([...dataValues]);
    SetCurrentData([...dataValues]);
  }

  let DataColumns = [];
  let nameColumn = {
    title: "Nombre",
    dataIndex: "field_name",
    key: "field_name",
    width: "40%",
  };
  DataColumns.push({
    title: "Acciones",
    key: "action",
    width: "5%",
    render: (text, record, index) => {
      return (
        <Popconfirm
          onConfirm={() => {
            handleDeleteValue(index);
          }}
          title="Borrar?"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <a
            href="#"
            style={{
              fontSize: "25px",
              width: "100%",
              display: "inline-block",
              "text-align": "center",
            }}
          >
            <DeleteOutlined />
          </a>
        </Popconfirm>
      );
    },
  });
  nameColumn.editable = true;
  nameColumn.onCell = (record, rowIndex) => ({
    record,
    index: rowIndex,
    editable: true,
    editing: true,
    dataIndex: "field_name",
    inputType: "string",
    title: "Nombre",
    handleSave: handleSave,
  });

  DataColumns.push(nameColumn);
  DataColumns.push({
    title: "Tipo de Dato",
    key: "field_type",
    dataIndex: "field_type",
    editable: true,
    width: "40%",
    onCell: (record, rowIndex) => ({
      record,
      index: rowIndex,
      editable: true,
      editing: true,
      dataIndex: "field_type",
      title: "Tipo de Dato",
      inputType: "select",
      handleSave: handleSave,
      //dataType:this.props.dataType
    }),
  });

  return (
    <Row>
      <Col span={1} />
      <Col span={22}>
        <Row>
          <Row>
            {/* <Col span={24}>
              <PageHeader
                className="form-page-header"
                backIcon={false}
                title="Campos"
              />
            </Col> */}
          </Row>
        </Row>
        <Row justify="space-between">
          <Col span={22}>
            <Button type="primary" onClick={addRow}>
              Agregar Campo
            </Button>
          </Col>
        </Row>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey="fieldID"
            bordered
            dataSource={data}
            columns={DataColumns}
            rowClassName="editable-row"
            className="petition-body-container"
            scroll={{ y: 376.9 }}
            pagination={{
              pageSize: 50,

              position: ["topCenter"],
            }}
          />
        </Form>
      </Col>
    </Row>
  );
};
export default BodyData;
