import React, { useEffect, useState } from "react";
import {Form, Input, Select, Col, Row, PageHeader, Divider, Button} from 'antd';
//import 'antd/dist/antd.css'
import './manager.css'
import _ from 'lodash'

const HSCFetchManagerForm = ({SetDataGetter, name, petition, body, description, generalTasks, apiKey, taskID, userOptions, executeUser, form, isNewData, updateAPIKEY}) => {
    // console.log("name", name);
    const [nameVal, setNameVal] = useState(name);
    const [descriptionVal, setDescriptionVal] = useState(description);
    const [apiKeyVal, setApiKeyVal] = useState(apiKey);
    const [taskIDVal, setTaskIDVal] = useState(taskID);
    const [formDataSet, setformDataSet] = useState(false);
    const [executeUserVal, setExecuteUserVal] = useState(executeUser);
    const [isNewDataVal, setIsNewDataVal] = useState(isNewData);
    const [buttonType, setButtonType] = useState(isNewData ? "default":"danger");

    const tasksList = generalTasks.map(function(task){
        return <Select.Option key={task.generalTask} value={task.generalTask}>{task.name}</Select.Option>
    });

    useEffect(() => {
        if(!formDataSet){
            // SetDataGetter("form",getFormData);
            setformDataSet(true);
            form.setFieldsValue({"name":name, "description":description, "keyValue":apiKey, "taskID":taskID, "executeUser":executeUser})
        }
        if(name!=nameVal){
            setNameVal(name)
            form.setFieldsValue({"name":name})
        }
        if(description!=descriptionVal){
            setDescriptionVal(description)
            form.setFieldsValue({"description":description})
        }
        if(apiKey!=apiKeyVal){
            setApiKeyVal(apiKey)
            form.setFieldsValue({"keyValue":apiKey})
        }
        if(taskID!=taskIDVal){
            setTaskIDVal(taskID)
            form.setFieldsValue({"taskID":taskID})
        }
        if(executeUser!=executeUserVal){
            setExecuteUserVal(executeUser)
            form.setFieldsValue({"executeUser":executeUser})
        }
        if(isNewData!=isNewDataVal){
            setIsNewDataVal(isNewData)
            setButtonType(isNewData ? "default":"danger")
        }
    }, [name, description, apiKey, taskID, executeUser, isNewData]);

    // function getFormData(){
    //     return form.getFieldsValue();
    // }

    return (
        <Row>
            <Col span={1}/>
            <Col span={22}>
                <Form form={form} autoComplete="new-password">
                    <Row>
                    <Col span={24}>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <PageHeader
                                className="form-page-header"
                                backIcon={false}
                                title="Datos de la petición"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5}>
                            <Form.Item autoComplete="off" label={'Nombre de la Petición'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="name">
                                <Input autoComplete="off" maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Row className="Api-Key-Row-Fetch">
                            <Form.Item autoComplete="off"  label={'API-KEY'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="keyValue">
                                <Input.Password autoComplete="off" onKeyPress={(e)=>{e.preventDefault();
      return false;}} visibilityToggle maxLength={50} />
                            </Form.Item>
                            </Row>
                            <Row className="Api-Key-Button-Row-Fetch">
                                    <Button onClick={updateAPIKEY} type={buttonType} disabled={isNewDataVal}>Actualizar API-KEY</Button>
                            </Row>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Form.Item  label={'Usuario ejecutor'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="executeUser">
                                <Select >
                                    {userOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Form.Item  label={'Tarea a ejecutar'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="taskID">
                                <Select >
                                    {tasksList}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={11}>
                            <Form.Item initialValue={"POST"} label={'Tipo de petición'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="petition">
                                <Select defaultValue={"POST"}>
                                    <Select.Option disabled value="GET">GET</Select.Option>
                                    <Select.Option disabled value="DELETE">DELETE</Select.Option> 
                                    <Select.Option disabled value="PATCH">PATCH</Select.Option>
                                    <Select.Option value="POST">POST</Select.Option>
                                    <Select.Option disabled value="PUT">PUT</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Form.Item initialValue={"JSON"} label={'Cuerpo de la petición'} rules={[{ required: true, message: "campo obligatorio"}]} className="tableDataInput" name="body">
                                <Select defaultValue={"JSON"}>
                                    <Select.Option value="JSON">JSON</Select.Option>
                                    <Select.Option disabled value="FORM">formulario</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider type="horizontal"/>
                    <Row>
                        <Col span={23}>
                            <Form.Item  label={'Descripción de la Petición'} className="tableDataInput" name="description">
                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default HSCFetchManagerForm;