import React, { useEffect, useState } from "react";
import HSCFetchManagerForm from "./HSCFetchManagerForm";
import HSCActionButtons from "./HSCActionButtons";
import BodyData from "./BodyData";
import { notification, Divider, Form } from "antd";

const HSCFetchManagerFormContainer = ({saveFormData, formName, formDescription, formBodyFields, generalTasks, formKey, formTaskID, userOptions, formExecuteUser, SetCurrentData, getTableData, getLogs, getPetitionData, deletePetition, isNewData, updateAPIKEY}) => {
    const [form] = Form.useForm();
    const [name, setName] = useState(formName);
    const [description, setDescription] = useState(formDescription);
    const [bodyFields, setBodyFields] = useState(formBodyFields);
    const [apiKey, setApiKey] = useState(formKey);
    const [taskID, setTaskID] = useState(formTaskID);
    const [executeUser, setExecuteUser] = useState(formExecuteUser);
    const [isNewDataVal, setIsNewDataVal] = useState(isNewData);
    let getFormData = null;

    useEffect(() => {
        setName(formName);
        setDescription(formDescription);
        setBodyFields(formBodyFields);
        setApiKey(formKey);
        setTaskID(formTaskID);
        setExecuteUser(formExecuteUser);
        setIsNewDataVal(isNewData)
    }, [formName, formDescription, formBodyFields, formKey, formTaskID, formExecuteUser, isNewData]);

    function SetDataGetter(type, callBack){
        if(type=="form"){
            getFormData=callBack
        }
    }

    function validateData(formData, tableData){
        if(formData.name=="" || formData.name==null){
            notification.error({
                message: "Error!",
                description: "El nombre de la petición no puede ir vacio."
              });
              return false;
        }
        if(formData.executeUser=="" || formData.executeUser==null){
            notification.error({
                message: "Error!",
                description: "Debe seleccionar un Usuario Ejecutor."
              });
              return false;
        }
        if(formData.taskID=="" || formData.taskID==null){
            notification.error({
                message: "Error!",
                description: "Debe seleccionar una tarea."
              });
              return false;
        }
        if(tableData.length <=0){
            notification.error({
                message: "Error!",
                description: "La petición no puede estar sin campos"
              });
              return false;
        }
        return true;
    }

    function saveData(){
        let formData = form.getFieldsValue();
        let tableData = getTableData();
        if(validateData(formData, tableData)){
            saveFormData(formData, tableData);
        }
    }

    return (
        <>
            <HSCFetchManagerForm
                    SetDataGetter={SetDataGetter}
                    name={name}
                    description={description}
                    apiKey={apiKey}
                    taskID={taskID}
                    generalTasks={generalTasks}
                    userOptions={userOptions}
                    executeUser={executeUser}
                    form={form}
                    isNewData={isNewDataVal}
                    updateAPIKEY={updateAPIKEY}
                />
            <BodyData
                    SetCurrentData={SetCurrentData}
                    bodyFields={formBodyFields}
                />
            <Divider type="horizontal" />
            <HSCActionButtons
                    saveData={saveData}
                    getLogs={getLogs}
                    getPetitionData={getPetitionData}
                    deletePetition={deletePetition}
                    isNewData={isNewDataVal}
                />
        </>
    )
}

export default HSCFetchManagerFormContainer;